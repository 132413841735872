
import { defineComponent } from "vue";
import Validation from "@/components/Validation.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";

export default defineComponent({
  name: "Form6",
  components: { FscMultiselect, Validation },
  props: {
    v: {},
    data: {
      required: true,
    },
  },
  emits: ["next", "back"],
  setup(props, { emit }) {
    const onNext = () => {
      //@ts-ignore
      props.v.$touch();
      //@ts-ignore
      if (props.v.$invalid) return;
      emit("next");
    };

    const onBack = () => {
      emit("back");
    };

    const salutations = ["Herr", "Frau"];

    return {
      onNext,
      salutations,
      onBack,
    };
  },
});
