
import { defineComponent } from "vue";
import FscMultiselect from "@/components/FscMultiselect.vue";
import Validation from "@/components/Validation.vue";
import useHelper from "@/composable/useHelper";

export default defineComponent({
  name: "Form4",
  components: {
    Validation,
    Multiselect: FscMultiselect,
  },
  props: {
    v: {},
    data: {
      required: true,
    },
    submitBtnText: {
      default: () => "Ausbildungsvertrag unverbindlich anfragen!",
    },
    loading: {
      default: () => false,
    },
    languages: {
      default: () => [],
    },
    licenseClasses: {
      default: () => [],
    },
    nationalities: {
      default: () => [],
    },
    languagesLoading: {
      default: () => false,
    },
    licenseClassesLoading: {
      default: () => false,
    },
    nationalitiesLoading: {
      default: () => false,
    },
  },
  emits: ["submit", "back"],
  setup(props, { emit }) {
    const { onOpenMultiselect, onCloseMultiselect } = useHelper();

    const onSubmit = () => {
      emit("submit");
    };
    const onBack = () => {
      emit("back");
    };
    return {
      onSubmit,
      onBack,
      onOpenMultiselect,
      onCloseMultiselect,
    };
  },
});
