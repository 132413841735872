
import { computed, defineComponent, watch } from "vue";

export default defineComponent({
  name: "CustomRadio",
  props: {
    id: String,
    modelValue: { type: [Boolean, String, Array, Object, Number], default: null },
    disabled: { type: Boolean, default: false },
    value: { type: [String, Boolean, Object, Number], default: true },
  },
  emits: ["update:modelValue", "change", "input"],
  setup(props, { emit }) {
    const localChecked: any = computed({
      get: () => props.modelValue,
      set: (newValue: any) => {
        emit("input", newValue);
        emit("change", newValue);
        emit("update:modelValue", newValue);
      },
    });

    const handleClick = async (checked: boolean) => {
      if (Array.isArray(props.modelValue)) {
        if ((props.modelValue || [])[0] !== props.value) {
          localChecked.value = [props.value];
        }
      } else if (checked && props.modelValue !== props.value) {
        localChecked.value = props.value;
      }
    };

    watch(
      () => props.modelValue,
      (newValue) => {
        emit("input", newValue);
      }
    );

    return {
      handleClick,
    };
  },
});
