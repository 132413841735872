
import { defineComponent } from "vue";
import Validation from "@/components/Validation.vue";
import FscDatepicker from "@/components/FscDatepicker.vue";

export default defineComponent({
  name: "Form1",
  components: { FscDatepicker, Validation },
  props: {
    v: {
      required: true,
    },
    data: {
      required: true,
    },
  },
  emits: ["next", "back"],
  setup(props, { emit }) {
    const onNext = () => {
      //@ts-ignore
      props.v.$touch();
      //@ts-ignore
      if (props.v.$invalid) return;
      emit("next");
    };

    const onBack = () => {
      emit("back");
    };

    return {
      onNext,
      onBack,
    };
  },
});
